import GitHubCalendar from 'github-calendar'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import ProjectGrid from '../components/projectsGrid'
import SEO from '../components/seo'
import { filterProjects } from '../utils'

// Can move to const later
const apiURL = `https://anshumanv-api.herokuapp.com/repos`

const WorkPage = () => {
	const [projects, setProjects] = useState([])

	useEffect(() => {
		// embed GitHub calendar
		GitHubCalendar(`.gh-calendar`, `anshumanv`, {
			responsive: true
		})

		fetch(apiURL).then(res =>
			res.json().then(work => setProjects(filterProjects(work)))
		)
		return () => {
			// console.log('cleanup')
		}
	}, [])

	return (
		<Layout>
			<SEO
				description="Showcasing my projects"
				keywords={[`anshumanv`, `gatsby`, `projects page`, `projects`]}
				title="Projects"
			/>

			<section className="flex flex-col h-full">
				{/* <h1 className="text-xl">Work</h1> */}
				<section className="flex mt-5 flex-col">
					<h2 className="my-3 text-lg">Here's my GitHub calendar</h2>
					<div className="gh-calendar"></div>
					<h2 className="my-10 text-xl">Projects</h2>
					<ProjectGrid projects={projects} />
				</section>
			</section>
		</Layout>
	)
}

export default WorkPage
