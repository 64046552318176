import React from 'react'
import ExternLink from './externLink'

const ProjectGrid = ({ projects }) => {
	return (
		<section className="flex flex-wrap -mx-3 justify-center">
			{projects.map(project => (
				<ExternLink
					className="w-full xs:w-1/3 md:w-1/4 flex-grow my-3 mx-5 max-w-xs"
					href={project.html_url}
					key={project.id}
				>
					<div className="bg-gray-800 h-full rounded flex flex-col overflow-hidden shadow-lg">
						<div className="px-6 py-4 flex-grow">
							<div className="font-bold text-xl mb-2">{project.name}</div>
							<p className="text-gray-200 text-base">{project.description}</p>
						</div>
						<div className="px-6 py-4 flex flex-wrap">
							<span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
								{project.stargazers_count} 🌟
							</span>
							<span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
								#{project.language}
							</span>
						</div>
					</div>
				</ExternLink>
			))}
		</section>
	)
}

export default ProjectGrid
